<template>
  <UiPopup
    :model-value="modelValue"
    :title="`Remove all permissions`"
    primary-button-text="Remove permission"
    secondary-button-text="Cancel"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="confirm()"
  >
    {{
      `If you remove all extra permissions for ${userName}, he will lose access to some data and get the standard set of
      permissions for his role.`
    }}
    <p class="mt-3">Are you sure you want to remove permissions for user?</p>
  </UiPopup>
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  userName: string
}

defineProps<Props>()

const confirm = () => {
  emits('input')
  emits('update:modelValue', false)
}
</script>

<style scoped></style>
